import { MainSlider } from 'components/SliderBanner';

const Slider = ({ cmsItem, isPLP, className = 'bg-dark-white', bannerClassName, slideChangeClassName }: any) => {
  return (
    <div className={`${className}`}>
      <MainSlider
        data={cmsItem?.data}
        isPLP={isPLP}
        bannerClassName={bannerClassName}
        slideChangeClassName={slideChangeClassName}
      />
    </div>
  );
};

export default Slider;
