import React, { memo, useContext, useMemo } from 'react';
import ProductsCarousel from 'components/CMS/ProductCarousel';
import CategoryCarousel from 'components/CMS/CategoriesCarousel';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ProductGrid from 'components/ProductGrid';
import Slider from 'components/CMS/Slider';
import CategoriesGrid from 'components/CMS/CategoriesGrid';
import FullBanner from 'components/CMS/FullBanner';
import SingleBanner from 'components/CMS/SingleBanner';
import RichText from 'components/CMS/RichText';
import { DeviceDetector } from 'utils/dd';
interface ICmsBlocksProps {
  cmsData: any;
  center?: boolean;
  isMobileFlagFromServerSide?: boolean;
  isPLP?: boolean;
  isBot?: boolean;
}

const CmsBlocks = (props: ICmsBlocksProps) => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { cmsData, center = true, isMobileFlagFromServerSide, isPLP = false } = props;
  const pickedImgUrl = useContext(DeviceDetector).isMobile ? 'mobileUrl' : 'url';
  const renderCmsBlocks = useMemo(() => {
    return (
      cmsData?.data &&
      cmsData?.data.map((cmsItem: any, key: number) => {
        switch (cmsItem.type) {
          case 'slider':
            return <Slider isPLP={isPLP} key={key} cmsItem={cmsItem} />;
          case 'categories_carousel':
            return <CategoryCarousel isPLP={isPLP} key={key} cmsItem={cmsItem} push={push} t={t} />;
          case 'categories_grid':
            return <CategoriesGrid key={key} cmsItem={cmsItem} push={push} t={t} />;
          case 'product_carousel':
            return <ProductsCarousel key={key} cmsItem={cmsItem} center={center} push={push} t={t} />;
          case 'product_grid':
            return <ProductGrid key={key} cmsItem={cmsItem} item_list_name={cmsItem.title} />;
          case 'single_banner':
            return <SingleBanner key={key} cmsItem={cmsItem} pickedImgUrl={pickedImgUrl} />;
          case 'full_banner':
            return <FullBanner key={key} cmsItem={cmsItem} pickedImgUrl={pickedImgUrl} />;
          case 'rich_text':
            return <RichText key={key} cmsItem={cmsItem} />;
        }
      })
    );
  }, [cmsData]);

  return <>{renderCmsBlocks}</>;
};

export default memo(CmsBlocks);
